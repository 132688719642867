import store from '@/store';
import { steps as MobileOnboardingSteps } from '@/utils/mobileOnboarding';
import MobileOnboardingScreens from '@/views/MobileOnboarding/Screens';
import { deletedRoutes } from '@/router/routes/deletedroutes';
import { accountSetupRoute } from '@/router/routes/accountSetupRoutes';
import { lsRemove, lsRetrieve } from '@/helpers/localStorage';
import { LS_WIZARD_PATH } from '@/views/Wizard/consts';
import { getAccountIdFromCookie } from '@/util';
import { ACCOUNT_TYPES, PARTNER_TYPES } from '@om/payment/src/partnerType';
import { verifyOnboarding } from '../utils';
import insertCodeRoutes from './codeinsert';
import newCampaignFlowRoutes from './newCampaignFlow/routes';

export const omRoutes = [
  {
    path: '/404',
    name: 'not_found',
    component: () => import('@/views/NotFoundComponent.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/connect-shopify',
    name: 'connect_shopify',
    component: () => import('@/views/ConnectShopify.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/register/:lang?/:type?',
    name: 'registration',
    component: () => import('@/views/Register.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/expired-email-verification',
    name: 'expired_email_verification',
    component: () => import('@/views/ExpiredEmailVerification.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/login/:lang?',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/oauth-login/:service?',
    name: 'oauth_login',
    component: () => import('@/views/OAuthLogin.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/shared/campaign/:token',
    name: 'campaign_share',
    component: () => import('@/views/Shared/CampaignShare.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/:userId/wizard/:step',
    name: 'wizard',
    component: () => import('@/views/Wizard/Wizard.vue'),
    meta: { permission: ['normal'] },
  },
  {
    path: '/:userId/partner-dashboard',
    name: 'agency_dashboard',
    component: () => import('@/views/AgencyDashboard.vue'),
    meta: {
      permission: [ACCOUNT_TYPES.AGENCY, ACCOUNT_TYPES.NORMAL],
      partnerPermission: [PARTNER_TYPES.AGENCY, PARTNER_TYPES.RESELLER, PARTNER_TYPES.CUSTOM],
    },
  },
  {
    path: '/:userId/campaign/:id/create-goal/:new',
    name: 'dc_goal_create',
    component: () => import('@/views/DCGoalCreation.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/campaign/:id/settings/:new?',
    name: 'campaign_settings',
    component: () => import('@/views/CampaignSettings.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/campaign/:id/:new?',
    name: 'campaign_variants',
    component: () => import('@/views/CampaignVariants.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/use-case/:id/rules',
    name: 'use_case_rules',
    component: () => import('@/views/UseCaseRules.vue'),
    meta: { permission: ['normal', 'sub', 'agency'] },
  },
  {
    path: '/:userId/variant/:campaignId/:id/edit/:new?',
    name: 'variant_edit',
    component: () => import('@/editor/components/Editor.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/template-editor/:baseTemplate',
    name: 'template_editor',
    component: () => import('@/editor/components/Editor.vue'),
    meta: { permission: ['normal', 'sub', 'agency'] },
  },
  {
    path: '/:userId/campaigns',
    name: 'campaigns',
    component: () => import('@/views/MultiCampaigns.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/analytics',
    name: 'analytics',
    component: () => import('@/views/Analytics.vue'),
    meta: { permission: ['normal', 'sub', 'agency'] },
  },
  {
    path: '/:userId/templates',
    alias: '/:userId/templates/',
    // use the "templates" pathname, not this if you want to navigate to the templates homepage
    name: 'template_selector',
    component: () => import('@/components/TemplateChooser/View.vue'),
    meta: { permission: ['normal', 'sub'], disableScrollTop: true },
    children: [
      {
        path: '',
        component: () => import('@/components/TemplateChooser/children/Home.vue'),
        name: 'templates',
        meta: { permission: ['normal', 'sub'], disableScrollTop: true },
      },
      {
        path: '/:userId/templates/custom-templates',
        component: () => import('@/components/TemplateChooser/children/Listing.vue'),
        name: 'custom-templates',
        meta: { permission: ['normal', 'sub'], disableScrollTop: true },
      },
      {
        path: '/:userId/templates/your-themes',
        component: () => import('@/components/TemplateChooser/children/Collection.vue'),
        name: 'your-themes-collection',
        meta: { permission: ['normal', 'sub'] },
        children: [
          {
            path: '/:userId/templates/your-themes/:slug',
            component: () => import('@/components/TemplateChooser/children/Listing.vue'),
            name: 'your-themes-templates',
            meta: { permission: ['normal', 'sub'], disableScrollTop: true },
          },
        ],
      },
      {
        path: '/:userId/templates/themes',
        component: () => import('@/components/TemplateChooser/children/Collection.vue'),
        name: 'themes-collection',
        meta: { permission: ['normal', 'sub'], disableScrollTop: true },
        children: [
          {
            path: '/:userId/templates/themes/:slug',
            component: () => import('@/components/TemplateChooser/children/Listing.vue'),
            name: 'themes-templates',
            meta: { permission: ['normal', 'sub'], disableScrollTop: true },
          },
        ],
      },
      {
        path: '/:userId/templates/seasonal',
        component: () => import('@/components/TemplateChooser/children/Collection.vue'),
        name: 'seasonal-collection',
        meta: { permission: ['normal', 'sub'], disableScrollTop: true },
        children: [
          {
            path: '/:userId/templates/seasonal/:slug',
            component: () => import('@/components/TemplateChooser/children/Listing.vue'),
            name: 'seasonal-templates',
            meta: { permission: ['normal', 'sub'], disableScrollTop: true },
          },
        ],
      },
    ],
  },
  {
    path: '/:userId/oldVariant/:caId/:id/edit',
    name: 'old_variant_edit',
    component: () => import('@/components/OldEditor.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: { permission: ['normal', 'sub'], props: { newsletterPopup: true } },
  },
  {
    path: '/forgotten-password/:token?',
    name: 'forgotten-password',
    component: () => import('@/components/AccountSettings/NewUserPassword.vue'),
    meta: { forgottenPassword: true, withoutUserId: true },
  },
  {
    path: '/new-user-password/:token',
    name: 'new-user-password',
    component: () => import('@/components/AccountSettings/NewUserPassword.vue'),
    meta: { forgottenPassword: false, withoutUserId: true },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/Help.vue'),
    meta: { permission: ['agency', 'normal', 'sub'], withoutUserId: true },
  },
  {
    path: '/:userId/leads/:campaignId?',
    name: 'subscribers',
    component: () => import('@/views/Audience.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/segment/:id',
    name: 'edit_segment',
    component: () => import('@/views/EditSegment.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/invitation/:lang/:hash',
    name: 'invitation',
    component: () => import('@/views/Invitation.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/notify/:lang/:dbId/:hash/accept',
    name: 'notify-me',
    component: () => import('@/views/NotifyMe.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/:userId/plan-settings',
    name: 'plan_settings',
    component: () => import('@/views/PlanSettings.vue'),
    meta: {
      permission: ['agency', 'normal'],
      denied: ['simple-shared'],
    },
  },
  {
    path: '/:userId/checkout/:step?',
    name: 'checkout',
    component: () => import('@/views/Checkout.vue'),
    meta: {
      permission: ['agency', 'normal'],
      denied: ['simple-shared'],
    },
  },
  {
    path: '/:userId/shopify-checkout',
    name: 'shopify_checkout',
    component: () => import('@/views/ShopifyCheckout.vue'),
    meta: {
      permission: ['agency', 'normal'],
      denied: ['simple-shared'],
    },
  },
  {
    path: '/switch-account',
    name: 'switch_account',
    component: () => import('@/views/SwitchAccount.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/:userId/integrations',
    component: () => import('@/views/Integrations.vue'),
    alias: '/:userId/integrations',
    name: 'integrations',
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/become-ambassador',
    component: () => import('@/views/BecomeAmbassador.vue'),
    name: 'become_ambassador',
    meta: { permission: ['normal'], roles: ['owner'], isAffiliate: false },
    beforeEnter: (to, from, next) => {
      const isImpersonate = store.getters.isImpersonate;
      const region = store.getters.getRegion;

      if (isImpersonate || region === 'Hungary') {
        next({ name: 'dashboard' });
      } else {
        next();
      }
    },
  },
  {
    path: '/:userId/settings',
    component: () => import('@/views/AccountSettings.vue'),
    children: [
      {
        path: 'personal',
        component: () => import('@/components/AccountSettings/PersonalDetails.vue'),
        alias: '/personal-details',
        name: 'account_settings',
        meta: { permission: ['agency', 'normal', 'sub'] },
      },
      {
        path: 'change-email',
        component: () => import('@/components/AccountSettings/ChangeEmail.vue'),
        name: 'change_email',
        meta: { permission: ['agency', 'normal', 'sub'] },
      },
      {
        path: 'invoice-history',
        component: () => import('@/components/AccountSettings/InvoiceHistory.vue'),
        name: 'invoice_history',
        meta: { permission: ['agency', 'normal'] },
        denied: ['simple-shared'],
      },
      {
        path: 'change-password',
        component: () => import('@/components/AccountSettings/ChangePassword.vue'),
        name: 'change_password',
        meta: { permission: ['agency', 'normal', 'sub'] },
      },
      {
        path: 'billing-address',
        component: () => import('@/components/AccountSettings/BillingAddress.vue'),
        name: 'billing_address',
        meta: { permission: ['agency', 'normal'] },
        denied: ['simple-shared'],
      },
      {
        path: 'spam-protection',
        component: () => import('@/components/AccountSettings/SpamProtection.vue'),
        name: 'spam_protection',
        meta: { permission: ['agency', 'normal', 'sub'] },
      },
      {
        path: 'conversion-goals',
        component: () => import('@/components/AccountSettings/ConversionGoals.vue'),
        name: 'conversion_goals',
        meta: { permission: ['normal', 'sub'] },
      },
      {
        path: 'payment-method',
        component: () => import('@/components/AccountSettings/PaymentMethodChange.vue'),
        name: 'payment_method',
        meta: { permission: ['agency', 'normal'] },
        denied: ['simple-shared'],
      },
      {
        path: 'white-label-settings',
        component: () => import('@/components/AccountSettings/WhiteLabelSettings.vue'),
        name: 'white_label_settings',
        meta: { permission: ['agency', 'normal', 'sub'] },
      },
      {
        path: 'account-sharing',
        component: () => import('@/components/AccountSettings/AccountSharing.vue'),
        name: 'account_sharing',
        meta: { permission: ['agency', 'normal', 'sub'], denied: ['any-shared'] },
      },
      {
        path: 'domains',
        component: () => import('@/components/AccountSettings/Domains.vue'),
        name: 'domains',
        meta: { permission: ['agency', 'normal', 'sub'] },
      },
      {
        path: 'api-keys',
        component: () => import('@/components/AccountSettings/ApiKeys.vue'),
        name: 'apikey_settings',
        meta: { permission: ['agency', 'normal', 'sub'] },
      },
      {
        path: 'experimental',
        component: () => import('@/components/AccountSettings/Experimental.vue'),
        alias: '/experimental',
        name: 'experimental',
        meta: { permission: ['normal', 'sub'] },
      },
      {
        path: 'features',
        component: () => import('@/components/AccountSettings/Features.vue'),
        alias: '/features',
        name: 'features',
        meta: { permission: ['normal', 'sub'] },
      },
      {
        path: 'user-experience-protector',
        component: () => import('@/components/AccountSettings/GlobalFrequencyCap.vue'),
        alias: '/user-experience-protector',
        name: 'user_experience_protector_settings',
        meta: { permission: ['normal', 'sub'] },
      },
      insertCodeRoutes('code-insert', 'settings-code-insert'),
    ],
  },
  {
    path: '/:userId/ambassador-dashboard',
    component: () => import('@/views/AffiliateDashboard.vue'),
    name: 'ambassador_dashboard',
    meta: { permission: ['agency', 'normal', 'sub'], roles: ['owner'], isAffiliate: true },
  },
  {
    path: '/:userId/ambassador/payout/request',
    component: () => import('@/views/AffiliatePayoutRequest.vue'),
    name: 'ambassador_dashboard_payout_request',
    meta: { permission: ['agency', 'normal', 'sub'], roles: ['owner'], isAffiliate: true },
  },
  {
    path: '/:userId/template-manager',
    name: 'template_manager',
    component: () => import('@/views/TemplateManager/TemplateManager.vue'),
    meta: { permission: ['normal', 'agency'] },
  },
  {
    path: '/:userId/onboarding/non-wizard/:step',
    name: 'onboarding_non_wizard',
    component: () => import('@/views/NonWizard/NonWizard.vue'),
    beforeEnter: verifyOnboarding,
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/mobile-onboarding',
    component: () => import('@/views/MobileOnboarding/Stepper.vue'),
    meta: { permission: ['normal'] },
    beforeEnter: verifyOnboarding,
    children: [
      {
        path: '',
        beforeEnter: (_, __, next) => next({ name: MobileOnboardingSteps.WELCOME.name }),
      },
      ...Object.values(MobileOnboardingSteps).map(({ path, name }) => {
        const component = MobileOnboardingScreens[name];
        const meta = { permission: ['normal'] };
        return { path, component, name, meta };
      }),
    ],
  },
  {
    path: '/:userId/agency-benefits/:step',
    component: () => import('@/views/AgencyBenefitsMobile/Stepper.vue'),
    name: 'agency_benefits_steps',
    meta: { permission: ['normal'] },
  },
  {
    path: '/:userId/onboarding/wizard/:step',
    name: 'onboarding_wizard',
    component: () => import('@/views/Wizard/Wizard.vue'),
    meta: { permission: ['normal'] },
  },
  {
    path: '/:userId/user-experience-protector/:campaignId?/:new?',
    name: 'user_experience_protector',
    component: () => import('@/views/GlobalFrequencyCap.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/enable-app-embed/:campaignId?/:new',
    name: 'enable_app_embed',
    component: () => import('@/views/EnableAppEmbed.vue'),
    meta: { permission: ['normal', 'sub'], hideNav: true },
  },
  {
    path: '/connect/:shop',
    name: 'connect_shop',
    component: () => import('@/views/ConnectShop.vue'),
    meta: { withoutUserId: true },
  },
  {
    path: '/:userId/mobile-redirect/:step?',
    name: 'mobile_redirect',
    component: () => import('@/views/MobileRedirect.vue'),
  },
  {
    path: '/:userId/still-on-mobile',
    name: 'still_on_mobile',
    component: () => import('@/views/StillOnMobile.vue'),
  },
  {
    path: '/style-guide',
    name: 'style_guide',
    component: () => import('@/views/StyleGuide.vue'),
    meta: { permission: ['normal', 'sub'], withoutUserId: true },
  },
  {
    path: '/:userId/themes',
    name: 'themes_list',
    component: () => import('@/views/Themes/List.vue'),
    meta: { permission: ['normal', 'agency', 'sub'] },
  },
  {
    path: '/:userId/themes/:themeName',
    name: 'theme_details',
    component: () => import('@/views/Themes/Details.vue'),
    meta: { permission: ['normal', 'agency', 'sub'] },
  },
  {
    path: '/:userId/similarTemplates',
    name: 'similar_templates',
    component: () => import('@/views/SimilarTemplates.vue'),
    meta: { permission: ['normal', 'agency', 'sub'] },
  },
  {
    path: '/:userId/more-from-your-style',
    name: 'more_from_your_style',
    component: () => import('@/views/SimilarTemplates.vue'),
    meta: { permission: ['normal', 'agency', 'sub'] },
  },
  {
    path: '/:userId/your-theme/:id',
    name: 'custom_theme_details',
    component: () => import('@/views/CustomThemeDetails.vue'),
    meta: { permission: ['normal', 'agency', 'sub'] },
  },
  {
    path: '/:userId/use-case/:id',
    name: 'use_case_templates',
    component: () => import('@/views/SimilarTemplates.vue'),
    meta: { permission: ['normal', 'agency', 'sub'] },
  },
  {
    path: '/:userId/smart-wizard-brandkit/:step',
    name: 'independent_wizard',
    component: () => import('@/views/Wizard/Wizard.vue'),
    beforeEnter(_, from, next) {
      if (from.name !== 'independent_wizard') {
        const accountId = getAccountIdFromCookie();
        const saved = lsRetrieve(LS_WIZARD_PATH(accountId));
        if (saved) {
          lsRemove(LS_WIZARD_PATH(accountId));
          return next(saved);
        }
      }
      next();
    },
    meta: { permission: ['normal', 'sub'], hideNav: true },
  },
  {
    path: '/:userId/recommended-tactics',
    name: 'tactics_recommendation',
    component: () => import('@/views/Tactics/Recommendation.vue'),
    meta: { permission: ['normal', 'agency', 'sub'], hideNav: false },
  },
  {
    path: '/:userId/tactic/:id/templates',
    name: 'tactic_templates',
    component: () => import('@/views/SimilarTemplates.vue'),
    meta: { permission: ['normal', 'agency', 'sub'] },
  },
  {
    path: '/:userId/non-wizard/:step',
    name: 'non_wizard',
    component: () => import('@/views/NonWizard/NonWizard.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/themekit-check',
    name: 'themekit-check',
    component: () => import('@/views/ThemeKitCheck.vue'),
    meta: { permission: ['normal'] },
  },
  {
    path: '/:userId/experiments',
    name: 'experiments',
    component: () => import('@/views/Experiments.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/experiment/:experimentId',
    name: 'experiment',
    component: () => import('@/views/Experiment.vue'),
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/ai-features',
    name: 'ai_features',
    component: () => import('@/views/AIFeatures.vue'),
    meta: { permission: ['normal', 'sub', 'agency'], hideNav: true },
  },
  {
    path: '/:userId/ai-success',
    name: 'onboarding-ai-success',
    component: () => import('@/views/AISuccess.vue'),
    meta: { permission: ['normal'], hideNav: true },
  },
  {
    path: '/:userId/ai-success',
    name: 'ai-success',
    component: () => import('@/views/AISuccess.vue'),
    meta: { permission: ['normal'] },
  },
  {
    path: '/account-switching',
    name: 'account-switching',
    component: () => import('@/components/LoadingTransition.vue'),
    meta: { withoutUserId: true },
  },
  insertCodeRoutes('/:userId/code-insert', 'code-insert'),
  accountSetupRoute,
  ...deletedRoutes,
  newCampaignFlowRoutes,
];
