/* eslint-disable no-bitwise */
import { REGIONS } from '@/config/payment';
import * as Cookie from 'js-cookie';
import { get as _get, mergeWith } from 'lodash-es';
import moment from 'moment';
import slug from 'slugify';

const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]%@!$&'|()*+,;=]+$/;

const getPreviewForVariant = ({ variant, templateName }) => {
  let previewUrl = '';
  if (templateName) {
    // version 1 campaign
    templateName = templateName === 'Yoga' ? 'Yoga2' : templateName;
    previewUrl = `https://cdn-static.optimonk.com/bundles/wseintellipopup/Template/${templateName}/images/small.jpg`;
  } else {
    if (!variant) return '';
    const time = new Date(variant.previewGeneratedAt).getTime();
    previewUrl = `${_get(variant, 'previewURLs.0')}?ts=${time}`;
  }
  return previewUrl;
};

const getPreviewVariant = (campaign) => {
  if (campaign.version === 1) {
    return;
  }

  let variant;
  const variants = campaign.variants.filter(
    (v) => v.previewURLs && v.previewURLs.length > 0 && !v.isControlVariant,
  );
  variant = variants.find((v) => v.status === 'active');
  if (!variant && variants.length > 0) variant = variants[0];
  return variant;
};

const getPreviewUrlStyle = (campaign) => {
  let style = '';
  if (campaign) {
    let variant;
    let templateName;
    if (campaign.version === 1) {
      templateName = campaign.templateName === 'Yoga' ? 'Yoga2' : campaign.templateName;
      style = getPreviewForVariant({ templateName });
    } else if (campaign.variants) {
      variant = getPreviewVariant(campaign);

      if (variant) {
        style = getPreviewForVariant({ variant, templateName });
      }
    }
  }

  return style;
};

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

function formatCurrencyByRegion(region, value) {
  return `${REGIONS[region].prefix}${value} ${REGIONS[region].suffix}`.trim();
}

function separatePrice(price) {
  let priceNumber;
  let prefix = '';
  let suffix = '';
  const priceParsingRegex = /^(\D+)?(\d+\.?\d*)(\D+)?$/;
  const match = priceParsingRegex.exec(price);

  if (match) {
    prefix = match[1] || '';
    priceNumber = parseFloat(match[2]);
    suffix = match[3] || '';
  }

  prefix = prefix.trim();
  suffix = suffix.trim();

  return { prefix, priceNumber, suffix };
}

function validateDomain(v, isDynamicContent) {
  if (typeof v !== 'string') return false;

  const parts = v.split('.');
  if ((v.includes('www') && parts.length <= 2) || parts.length <= 1) return false;

  const tld = parts.pop();
  const tldRegex = /^[a-zA-Z0-9]+\/*$/gi;

  if (!tldRegex.test(tld) && !isDynamicContent) return false;

  return parts.every(function (host) {
    const hostRegex =
      /^((\w+:|^)\/{2}|(?!:\/\/))([a-zA-Z0-9áéűúőóüöíÁÉŰÚŐÓÜÖÍ]+|[a-zA-Z0-9áéűúőóüöíÁÉŰÚŐÓÜÖÍ][a-zA-Z0-9áéűúőóüöíÁÉŰÚŐÓÜÖÍ-]*[a-zA-Z0-9áéűúőóüöíÁÉŰÚŐÓÜÖÍ])$/gi;

    return hostRegex.test(host);
  });
}

function validateUrl(v) {
  return urlRegex.test(v);
}

function removeProtocolFromDomain(v) {
  return v.replace(/(^\w+:|^)\/\//, '').replace(/\/.*/, '');
}

function removeWWWFromDomain(v) {
  return v.replace(/^www\./, '');
}

function cleanDomain(domain) {
  const domainWithoutProtocol = removeProtocolFromDomain(domain).toLowerCase();
  return removeWWWFromDomain(domainWithoutProtocol);
}

function getPureDomain(domain) {
  try {
    const urlWithProtocol = /^https?:\/\//.test(domain) ? domain : `https://${domain}`;
    const url = new URL(urlWithProtocol);
    return `${url.protocol}//${url.hostname}`;
  } catch (error) {
    console.error('Invalid URL:', error.message);
    return null;
  }
}

function codeInsertionStatus(domain) {
  if (!domain || !domain.lastRequestDate) return 'no_code';

  const duration = moment.duration(moment().diff(moment(domain.lastRequestDate)));
  const hours = duration.asHours();

  if (hours - 24 >= 0) return 'no_request_for_a_day';

  return 'accessible';
}

function needStartingSlash(operator) {
  return ['equals', 'notEquals', 'startsWith', 'notStartsWith'].includes(operator);
}

const _isObject = (value) => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

const nilMerge = (a, b) => (a == null ? b : a);

const nilMergeDeep = (a, b) =>
  _isObject(a) && !Array.isArray(a) ? mergeWith({}, a, b, nilMergeDeep) : nilMerge(a, b);

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const generateRandomStr = (length) =>
  [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');

function toASCIIString(str) {
  return slug(str, ' ');
}

const convertUserIdToPartnerId = (userId) => {
  return userId * 473 + 6729;
};

const _fixDateString = (date) =>
  /\d+-\d+-\d+\s\d+:\d+:\d+/.test(date) ? date.replace(' ', 'T') : date;

const getMomentObjectFromDate = (date) => {
  return moment.isMoment(date) ? date : moment(new Date(_fixDateString(date)));
};

export const stripTags = (str) => {
  const document = new DOMParser().parseFromString(`<div>${str}</div>`, 'text/html');
  return document.body.textContent || '';
};

const randomArrayIndex = (length) => {
  return Math.floor(Math.random() * length);
};

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
};

export const slugify = (str) => {
  const specialChars = {
    à: 'a',
    ä: 'a',
    á: 'a',
    â: 'a',
    æ: 'a',
    å: 'a',
    ë: 'e',
    è: 'e',
    é: 'e',
    ê: 'e',
    î: 'i',
    ï: 'i',
    ì: 'i',
    í: 'i',
    ò: 'o',
    ó: 'o',
    ö: 'o',
    ô: 'o',
    ø: 'o',
    ù: 'o',
    ú: 'u',
    ü: 'u',
    û: 'u',
    ñ: 'n',
    ç: 'c',
    ß: 's',
    ÿ: 'y',
    œ: 'o',
    ŕ: 'r',
    ś: 's',
    ń: 'n',
    ṕ: 'p',
    ẃ: 'w',
    ǵ: 'g',
    ǹ: 'n',
    ḿ: 'm',
    ǘ: 'u',
    ẍ: 'x',
    ź: 'z',
    ḧ: 'h',
    '·': '-',
    '/': '-',
    _: '-',
    ',': '-',
    ':': '-',
    '': '-',
  };

  return str
    .replace(/./g, (target) => specialChars[target] || target)
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
    .trim();
};

export const getAccountIdFromCookie = () => {
  return Cookie.get('x-om-account-id') || 0;
};

export const removeAccountIdFromCookie = () => {
  return Cookie.remove('x-om-account-id');
};

export const setAccountIdInCookie = (id) => {
  Cookie.set('x-om-account-id', id, { expires: 999 });
};

export const setPaymentProblemCookie = () => {
  Cookie.set('x-om-payment-problem', 1);
};

export const getPaymentProblemCookie = () => {
  return Cookie.get('x-om-payment-problem');
};

export const isFullScreenTemplate = (template) => {
  return (
    ['interstitial', 'fullscreen'].includes(template.type) ||
    template.overlayColor === 'gradient' ||
    template.overlayColor === 'image'
  );
};

export const isCenteredBottom = (template) => {
  return template.position === 8;
};

export const mobileWidth = (windowWidth) => {
  return windowWidth <= 767;
};

export const getConversionRate = (conversionRate) => {
  if (conversionRate < 0.5 || !conversionRate) return 'N/A';
  return `${(conversionRate || 0).toFixed(2) || 0}%`;
};

export const isPage = (element) => element.type === 'OmPage' && !element.isTeaser;

export const frame = () => {
  const workspace = document.getElementById('workspaceFrame');
  return workspace;
};

export const frameStore = () => {
  return frame().contentWindow.om.store;
};

export const generateObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const getEmbeddedTemplateHTML = (html, { hideOverlay, frontendUrl, device }) => {
  const isMobile = device === 'mobile';
  const contentBasedOnDevice = isMobile
    ? `
    <div class="om-mock-col om-mock-col-center">
      ${html.replace(' height: 100vh;', '')}
    </div>`
    : `<div class="om-mock-col om-mock-col-side"></div>
    <div class="om-mock-col om-mock-col-center">
      ${html.replace(' height: 100vh;', '')}
    </div>
    <div class="om-mock-col om-mock-col-side"></div>`;
  const footerBasedOnDevice = isMobile
    ? `<div class="om-mock-footer-col om-mock-footer-col-center"></div>`
    : `<div class="om-mock-footer-col"></div>
    <div class="om-mock-footer-col om-mock-footer-col-center"></div>
    <div class="om-mock-footer-col"></div>`;

  return `
  <div class="om-mock-body ${isMobile ? 'mobile' : ''}">
    <div class="om-mock-header"></div>
    <div class="om-mock-main">
      ${contentBasedOnDevice}
    </div>
    <div class="om-mock-footer">
      ${footerBasedOnDevice}
    </div>
  </div>
  <style>
    .om-mock-body {
      background: #F7F7F8;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
    }
    .om-mock-body.mobile {
      padding: 0 0.75rem;
    }
    .om-mock-body.mobile .om-mock-col-center  {
      margin: unset;
    }
    .om-mock-body.mobile .om-mock-footer-col-center  {
      margin: unset;
    }

    .om-mock-header,
    .om-mock-main,
    .om-mock-footer {
      width: 100%;
      flex-grow: 1;
      position: relative;
    }

    .om-mock-main {
      min-height: 5rem;
      height: max-content;
      max-height: 100%;
      flex-grow: 0;
      display: flex;
      border-radius: 8px;
    }

    .om-mock-col {
      position: relative;
    }

    .om-mock-col .om-holder.om-holder-fixed {
      position: static;
    }

    .om-mock-header {
      margin-bottom: 15px;
    }

    .om-mock-body.mobile .om-mock-header {
      margin-bottom: 22px;
    }

    .om-mock-body.mobile .om-mock-footer {
      margin-top: 22px;
    }

    .om-mock-footer {
      margin-top: 15px;
      display: flex;
    }

    .om-mock-header:after {
      content: "";
      position: absolute;
      background: #fff;
      height: 100%;
      width: 100%;
      border-radius: 8px;
    }

    .om-mock-col-center {
      width: 100%;
      margin: 0 15px;
    }

    .om-mock-col-side {
        width: 10%;
        height: 100%;
        background: white;
        border-radius: 8px;
    }

    .om-mock-header:after {
      top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .om-mock-footer:after {
      bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .om-mock-footer-col {
      background: #fff;
      border-radius: 8px 8px 0 0;
      flex: 1;
    }

    .om-mock-footer-col-center {
      margin: 0 15px;
    }
  </style>
  <style type="text/css">
    ::-webkit-scrollbar {width: 0 !important;height: 0 !important;}
    html, body {
      margin: 0;
      padding: 0;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    #om-campaign-0 {height: 100%;}
    div.om-holder {
      position: static;
      z-index: 0;
    }
    ${
      hideOverlay
        ? '#om-campaign-0 .om-overlay { background: transparent; } #om-campaign-0 .om-canvas { box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 10px !important; }'
        : ''
    }
  </style>
  <link href="${frontendUrl}/assets/css/om.base.css?v=${Date.now()}" rel="stylesheet">
  <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" type="text/css">
  <style id="custom-colors"></style>`;
};

export const getNormalTemplateHTML = (html, { isEmbedded, hideOverlay, frontendUrl }) => {
  return `
  <div class="om-holder">
    <div class="om-container">
      <div class="om-middle">
        <div class="om-iframe-container">
          <div id="om-campaign-0" class="om-workspace-content ${isEmbedded ? 'embedded' : ''}">
            ${html}
            <style type="text/css">
              ::-webkit-scrollbar {width: 0 !important;height: 0 !important;}
              html, body {
                margin: 0;
                padding: 0;
                scrollbar-width: none;
                -ms-overflow-style: none;
              }
              #om-campaign-0 {height: 100%;}
              div.om-holder {
                position: static;
                z-index: 0;
              }
              ${
                hideOverlay
                  ? '#om-campaign-0 .om-overlay { background: transparent; } #om-campaign-0 .om-canvas { box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 10px !important; }'
                  : ''
              }
            </style>
            <link href="${frontendUrl}/assets/css/om.base.css?v=${Date.now()}" rel="stylesheet">
            <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" type="text/css">
            <style id="custom-colors"></style>
          </div>
        </div>
      </div>
    </div>
  </div>`;
};

export const pushDataLayer = (data) => {
  if (window.dataLayer !== null) {
    window.dataLayer.push(data);
  }
};

const daysBetween = (date1, date2) => {
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);
  return momentDate2.diff(momentDate1, 'days');
};

const isToday = (dateString) => {
  const today = moment().startOf('day');
  const dateToCheck = moment(dateString);
  return today.isSame(dateToCheck, 'day');
};

const isYesterday = (dateString) => {
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const dateToCheck = moment(dateString);
  return yesterday.isSame(dateToCheck, 'day');
};

const isLastMonthPeriod = (date1, date2) => {
  const fromDate = moment(date1);
  const toDate = moment(date2);
  const today = moment().startOf('day');
  const diffInMonths = toDate.diff(fromDate, 'months');
  return (
    diffInMonths === 0 &&
    today.month() - 1 === fromDate.month() &&
    fromDate.month() === toDate.month() &&
    fromDate.date() === 1 &&
    toDate.date() === toDate.daysInMonth()
  );
};

function convertObjectToQueryParamsString(params) {
  if (Object.keys(params || {}).length) {
    const pairs = Object.keys(params)
      .map((key) => {
        const value = Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key];
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
    return `?${pairs}`;
  }
  return '';
}

function isDateOlderThanADay(date, day) {
  const currentDate = moment();
  const givenDate = moment(date);

  return currentDate.diff(givenDate, 'days') > day;
}

const purifyDomain = (domain) => {
  if (!domain) return domain;
  let lowerCase = domain.toLowerCase();
  if (lowerCase.startsWith('m.')) lowerCase = lowerCase.substring(2);
  else if (lowerCase.startsWith('www.')) lowerCase = lowerCase.substring(4);
  return lowerCase;
};

export {
  urlRegex,
  toASCIIString,
  isFloat,
  getPreviewForVariant,
  getPreviewVariant,
  getPreviewUrlStyle,
  formatCurrencyByRegion,
  separatePrice,
  validateDomain,
  validateUrl,
  removeProtocolFromDomain,
  removeWWWFromDomain,
  codeInsertionStatus,
  needStartingSlash,
  capitalizeFirstLetter,
  nilMergeDeep,
  getMomentObjectFromDate,
  generateRandomStr,
  randomArrayIndex,
  getRandomIntInclusive,
  convertUserIdToPartnerId,
  convertObjectToQueryParamsString,
  daysBetween,
  isToday,
  isLastMonthPeriod,
  isYesterday,
  isDateOlderThanADay,
  cleanDomain,
  getPureDomain,
  purifyDomain,
};
